//
// Theme style
//
$secondary: #e7f1ff;
$secondary-active: #ebf3ff;
$table-striped-bg: $secondary;
$popover-max-width: 400px;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

@import 'print';
@import 'custom';

hr {
  border-color: $gray-400;
}

@font-face {
  font-family: 'Helvethaica';
  src: url('../DB-Helvethaica-X.ttf');
}
@font-face {
    font-family: "Helvethaica";
    src: url("../DB-Helvethaica-X-bold.ttf");
    font-weight: bold;
}
