//
//  Tooltip
//

// Base
.tooltip {
  .tooltip-inner {
    box-shadow: var(--#{$prefix}tooltip-box-shadow);
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  &.tooltip-inverse {
    --#{$prefix}tooltip-color: var(--#{$prefix}dark-inverse);
    --#{$prefix}tooltip-bg: var(--#{$prefix}dark);
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .tooltip:not(.tooltip-inverse) {
      --#{$prefix}tooltip-bg: #{$tooltip-bg-dark};
      --#{$prefix}tooltip-box-shadow: #{$tooltip-box-shadow-dark};
    }
  }
}
