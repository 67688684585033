.fc {
  &-col-header-cell {
    background-color: $secondary;
  }
  &-theme-standard {
     td, th, .fc-scrollgrid {
        border-color: #1752aa!important;
        border-width: 0.5px!important;
        @include media-breakpoint-down(sm) {
          border-width: 1px!important;
        }
     }
  }
}
.fc-media-screen {
    background-color: white;
}
.iframe-schedule {
  .fc-daygrid-day-frame {
    background-color: white;
  }
  @include media-breakpoint-down(sm) {
    .event-title {
      font-size: 0.5rem!important;
    }
  }
  @include media-breakpoint-up(md) {
    .event-title {
      font-size: 0.75rem!important;
    }
  }
}
