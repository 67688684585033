.schedule {
  &__item-container {
    background-color: rgba(231, 159, 136);
    &--confirmed {
      background-color: #e4ffde;
      border: 1px solid $success;
    }
    &--treatment {
      background-color: #ade8f4;
      border: 1px solid #0096c7;
    }
    border: 1px solid #f35120;
    border-radius: 10px;
    padding: 0.15rem 1rem;
    z-index: 3;
    min-height: 30px;
  }
}
