@import './vendors/full-calendar';
@import './vendors/bootstrap';
@import './vendors/react-datepicker';

@import './modules/patient/show';
@import './modules/schedule/index';
@import './modules/treatment/index';
@import './dashboard';

$emptyThHeight: 100px;
body {
  height: auto;
  min-height: 100%;
}

html, body {
  font-family: "Helvethaica";
}

.popover {
  font-family: "Helvethaica"!important;
}

#kt_table_users {
  thead tr th:first-child {
    border-top-left-radius: 10px;
    //border-bottom-left-radius: 10px;
  }
  thead tr th:last-child {
    border-top-right-radius: 10px;
    //border-bottom-right-radius: 10px;
  }
}

.cross {
      position: relative;
      width: 40px;
      height: 40px;
}

.cross::before,
.cross::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 4px;
  background-color: red;
  transform-origin: center;
}

.cross::before {
  transform: translate(-50%, -50%) rotate(45deg); /* Diagonal line */
}

.cross::after {
  transform: translate(-50%, -50%) rotate(-45deg); /* Opposite diagonal line */
}

.dot {
  width: 10px;
  height: 10px;
}
.circle {
  width: 30px;
  height: 60px;
}

#report-problem-floating-button {
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  right: 50px;
  bottom: 30px;
  cursor: pointer;
  box-shadow: 1px 3px 3px 0px $gray-600;
}

.handbook {
  &__item {
    border: 1px solid $gray-400;
    padding: 1rem;
    border-radius: 10px;
    &-button-container {
      margin-top: 4rem;
    }
  }
}

.csv-exporter {
  &__in-table {
    position: absolute;
    right: 0;
    top: -45px;
    @include media-breakpoint-down(sm) {
      top: 10px;
      right: 10px;
    }
  }
}

/* begin::components */
.receipt-modal {max-width: 580px;}
.modal {
  &__title {
    color: $primary;
    border-bottom: 2px $primary solid;
  }
  .form-label {
    font-weight: bold;
  }
  &__title-center {
    background: $primary;
    justify-content: center;
    position: relative;
    padding: 1rem;
    .modal-title {
      color: $white;
    }
  }
}
.day-container {
   border: 1px solid $black;
   border-radius: 5px;
   width: 40px;
   height: 30px;
   &--selected {
     background-color: $secondary;
     border-color: $primary;
   }
}
.custom-uploader {
  position: relative;
  & input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 19;
  }
}
.schedule-popover {
  .popover-header, .popover-body {
      color: $white;
      min-width: 300px;
  }
  .popover-header {
    background-color: #232e49;
    border-bottom: 0;
  }
  .popover-body {
    background-color: #2b3859;
  }
  .popover-arrow {
     &::after {
      border-right-color: #2b3859;
    }
  }
}
/* end::components */

/* begin::utilities */
.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-vcenter {
  display: flex;
  align-items: center;
}
.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.row-edge-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-edge {
  display: flex;
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.link {
  color: $primary!important;
  i {
    color: $primary!important;
  }
}
.shadow {
  box-shadow: 5px 5px 5px 0px $gray-400;
  background-color: $white;
}
.absolute-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.flex-1 {
  flex: 1;
}
hr {
  &.--section-hr {
    border-top:2px solid $primary;
    opacity: 1;
  }
}
.bg-disabled {
  background-color: $gray-400;
}
/* end::utilities */

/* begin::common */
.section-title {
  color: $primary;
  border-bottom: 2px $primary solid;
}
/* end::common */

/* begin::react-select */
.react-select {
  &__value-container {
    height: 31px;
    &--is-multi {
      height: auto;
    }
  }
  &__control {
    border-color: $gray-300!important;
    border-radius: 0.475rem!important;
    padding: 0.4rem 0rem;
    &--is-focused {
        border-color: $gray-400!important;;
        box-shadow: none!important;
    }
  }
  &__indicator {
    padding: 0 8px!important;
  }
  &__clear-indicator {
    padding: 0px!important;
    padding-right: 8px!important;
  }
}
/* end::react-select */

/* begin::react-datepicker */
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__time-list-item--disabled {
  display: none;
}
/* end::react-datepicker */

/* begin::Setting Page */
.setting {
  &__title {
    display: flex;
    margin-bottom: 1rem;
    &:after {
      content: "";
      flex: 1;
      border-bottom: 1px solid $gray-300;
      margin-left: 8px;
      margin-bottom: 4px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 140px;
    height: 150px;
    border-radius: 1.5rem;
    cursor: pointer;
    margin-right: 1rem;
    padding-top: 1.5rem;
    paddin-bottom: 1rem;
  }
}
/* end::Setting Page */

/* begin::Patient Menu */
.patient {
  &-tabs {
    display: flex;
    &__item {
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: $secondary;
      padding: 15px 20px;
      @include media-breakpoint-down(sm) {
        .label {
          font-size: 1rem;
        }
      }
      border-left: 1px solid $gray-300;
      &--active {
        background-color: $primary;
        .label {
          color: $white;
        }
      }
    }
  }
  &-create {
    &__section-title {
      color: $primary;
      border-bottom: 2px $primary solid;
    }
    &__section-content {
      max-width: 90%;
      width: 600px;
    }
  }
}
/* end::Patient Menu */

/* begin::Doctor Schedule Menu */
.doctor-schedule {
  &__select {
    position: relative;
    z-index: 99;
    width: 100px;
  }
}
.schedule-week {
  td, th {
    height: 120px;
    min-width: 100px;
    &:first-child {
      min-width: 80px;
      width: 80px;
    }
    padding: 0;
  }
  th {
    vertical-align: top;
  }
  &__room-name {
    background-color: $secondary;
    padding: 10px 5px;
    &--wrapper {
      background-color: $white;
      display: flex;
      justify-content: center;
      border-radius: 20px;
      padding: 10px 0;
    }
  }
  &__sidebar {
    left: 0;
    top: 0;
    height: 100%;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 82px;
    &-header {
      display: flex;
      justify-content: center;
      flex: 1;
    }
    &-item {
      flex: 1;
      &--empty {
        flex: 0;
        flex-basis: $emptyThHeight;
      }
    }
  }
  &__item {
    border-radius: 10px;
    height: 100%;
    padding: 0 5px 10px 5px;
  }
  &__time {
    background-color: $success;
    width: 100%;
    height: 70%;
    border-radius: 15px;
  }
}
.schedule-day {
  td {
    height: 200px;
  }
  th {
    height: $emptyThHeight;
  }
  &__date {
     th {
       height: 60px;
       vertical-align: middle;
       text-align: center;
       background-color: $secondary;
       padding-left: calc(82px + 10px);
       padding-right: 10px;
       div {
         background-color: $white;
         border-radius: 20px;
         padding: 8px 0;
       }
     }
  }
  &__item {
    border: 0.5px solid $primary;
    border-top-width: 4px;
    border-radius: 5px;
    background-color: $white;
    z-index: 2;
  }
}
/* end::Doctor Schedule Menu */

input[readOnly].auto-fill {
  background-color: $gray-100;
}
