.form-check-label {
  color: $black;
}
.dataTable {
  thead {
    background-color: $primary;
    th {
      color: $white!important;
    }
  }
}
.table-striped > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-bg);
    color: var(--bs-table-striped-color);
  }

button[class^="btn btn-outline"] {
  background-color: $white;
}
.btn-outline-primary {
    &:hover {
      background-color: $gray-100;
      color: $primary;
    }
}
.table-bordered {
  th, td {
    border-left-width: 0;
    border-right-width: 0;
  }
}

@for $i from 1 through 100 {
  $zIndexBackdrop:  #{1020 + (5 * $i)};
  $zIndexContent:  #{1020 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}
