.dashboard {
  &__date {
    width: 300px;
  }
  &__table {
     &--income-by-branch {
       td, th {
         padding-top: 0.2rem!important;
         padding-bottom: 0.2rem!important;
         padding-right: 0.2rem!important;
         font-size: 0.75rem;
       }
       tr:last-child {
         border-color: $primary;
       }
     }
  }
}
