.treatment-item-selector {
  &__container {

    border: 1px solid $gray-300;
    border-radius: 10px;
    padding: 1rem;
    z-index: 3;
  }
}
.treatment {
  &__pending {
    &-payment--left-container {
      @include media-breakpoint-down(lg) {
          border-right: none!important;
      }
      tr.no-bg {
        td {
          --bs-table-accent-bg: none;
        }
      }
    }
  }
  &__deposit-box {
    width: 100px;
  }
  &__payment-method {
    border: 1px solid $primary;
    border-radius: 5px;
    margin-right: 0.75rem;
    width: 30%;
  }
}
