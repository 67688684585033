.patient-show {
  &__ldc-box {
      border-radius: 1.5rem;
      background-color: $secondary;
      overflow: hidden;
      border: 1px solid $gray-300;
      border-top: 0;
  }
  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    padding: 3rem 0;
    img {
      width: 100px;
    }
  }
  &__box-body {

  }
  &__info {
    &-label {
      width: 200px;
      font-weight: bold;
    }
    &-value {
      margin-left: 3rem;
    }
  }
  &__info-body {
    padding-bottom: 2rem;
    padding-left: 100px;
  }
}
