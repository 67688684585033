.x-print-indent {
    padding-left: 3rem;
}

.x-print-notice-box {
    padding: 1rem 1.5rem;
    text-indent: 3rem;
    font-weight: 600;
    line-height: 1.75;
    border: 2px solid #000;
}

.x-print-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 1rem;
    border: 1px solid #000000;
    img {
        visibility: hidden;
        opacity: 0;
    }
    &.-check {
        img {
            visibility: visible;
            opacity: 1;
        }
    }
}

.x-print-space-value {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    font-weight: 600;
    text-align: center;
    transform: translateX(-50%);
    &.-left {
        text-align: left;
        padding-left: 2rem;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px dotted black;
    }
}
